<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="d-flex mx-auto">
        <v-spacer></v-spacer>
        <img alt="Success" src="../../assets/rejected.png" height="150" />
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex mx-auto">
        <v-spacer></v-spacer>
        <span class="text-h4 orange--text text-center">Sorry, your payment was declined by the payment processor.</span>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex mx-auto">
        <v-spacer></v-spacer>
        <span class="text-h4 text-center">Please check your details and try again.</span>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex mx-auto">
        <v-spacer></v-spacer>
        <v-btn
        color="primary"
        class="white--text"
        elevation="0"
        @click="$router.push('/employer/paymentSchedule/' + $route.params.id)"
      >OK
      </v-btn>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  components: {},
  name: "DeclinedTransaction",
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
